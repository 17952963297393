<template>
    <div>
        <notification-popup
            :dialog="notifGetFailed"
            :contentMsg="$t('PleaseTryAgain')"
            :headerMsg="$t('FailedGetListNotification')"
            :nameBtnNo="$t('Close')"
            :nameBtnYes="$t('Reload')"
            colorIcon="danger"
            typeModal="transaction"
            Icon="mdi-close-circle"
            imgSource="notification/failed.svg"
            :onHandlerYes="buttonReload"
            :onHandlerNo="buttonClose"
        />
        <div class="top-nav">
            <div class="text-nav" v-on:click="handleAll"
                :style="{ borderColor: position == 'all' ?  $partnerACL.getBackgroundColorApp() : '#FFF' }">{{ $t('All') }}</div>
            <div class="text-nav" v-on:click="handleNotification('Purchase')"
                :style="{ borderColor: position == 'Purchase' ? $partnerACL.getBackgroundColorApp() : '#FFF' }">{{ $t('Purchase') }}</div>
            <div class="text-nav" v-on:click="handleNotification('Usage')"
                :style="{ borderColor: position == 'Usage' ? $partnerACL.getBackgroundColorApp() : '#FFF' }">{{ $t('Usage') }}</div>
            <div class="text-nav" v-on:click="handleNotification('TransportService')"
                :style="{ borderColor: position == 'TransportService' ? $partnerACL.getBackgroundColorApp() : '#FFF' }">{{ $t('TransportService') }}</div>
            <div class="text-nav" v-on:click="handleNotification('Profile')"
                :style="{ borderColor: position == 'Profile' ? $partnerACL.getBackgroundColorApp() : '#FFF' }">{{ $t('Profile') }}</div>
            <div class="text-nav" v-on:click="handleNotification('Maintenance' )"
                :style="{ borderColor: position == 'Maintenance' ? $partnerACL.getBackgroundColorApp() : '#FFF' }">{{ $t('Maintenance') }}</div>
        </div>
        <div class="content">
            <list-of-notification
                :item="filterList"
            />
        </div>
        <div class="d-flex justify-center mb-3">
            <b>{{ $t("Eof") }}</b>
        </div>
    </div>
</template>

<script>
import ListOfNotification from '@/components/ListOfNotification.vue'
import NotificationPopup from "@/components/NotificationPopup.vue";
import moment from 'moment'

export default {
    name: 'AllNotification',
    components: {
        ListOfNotification,
        NotificationPopup
    },
    data() {
        return {
            bgColorApp: this.$partnerACL.getBackgroundColorApp(),
            listNotification: [],
            filterList: [],
            position: 'all',
            notifGetFailed: false,
            eof: false,
            hasScroll: false,
            page: 0,
            limit: 20,
            lastPage: 1
        }
    },
    methods: {
        getListNotif() {
            this.page = this.page + 1;
            var payload = {
                page: this.page,
                limit: this.limit,
            };
            if (this.page <= this.lastPage) {
                this.$store
                    .dispatch("customer/GetListNotification", payload)
                    .then((response) => {
                        if (response.status) {
                            this.listNotification = this.listNotification.concat(response.data.data.map(notif => {
                                return {
                                    ...notif,
                                    date: notif.last_update !== '' ? moment(notif.last_update).format('YYYY-MM-DD hh:mm:ss') : ''
                                }
                            }))
                            this.filterList = this.listNotification
                            this.lastPage = response.data.lastPage
                        } else {
                            this.notifGetFailed = true
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // this.notifGetFailed = true
                    });
            }
        },
        handleAll() {
            this.filterList = this.listNotification
            this.position = 'all'
        },
        handleNotification(position) {
            this.filterList = this.listNotification.filter(fx => fx.type == position)
            this.position = position
        },
        buttonReload() {
            this.getListNotif()
            this.notifGetFailed = false
        },
        buttonClose(){
            this.notifGetFailed = false
        },
    },
    mounted() {
        window.addEventListener("scroll", () => {
            this.getListNotif()
            this.hasScroll = true
        })
        this.getListNotif()
    },
}
</script>

<style scoped>
.top-nav {
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 1rem 1rem 0px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    position: sticky;
    top: 50px;
    z-index: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.top-nav::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.top-nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.text-nav {
    /* color: #FFF; */
    font-size: medium;
    font-weight: 500;
    border-bottom: 3px solid;
    padding: 0px 2rem 0.5rem;
    cursor: pointer;
    white-space: nowrap;
}

.content {
    padding: 1rem;
}
</style>